import React from 'react';

function ScrollLine() {
  return (
    <div className="scroll-line-wrapper">
      <div className="ani ani--mask ">
        <div className="scroll-ind__bar">
          <div className="scroll-ind__bar-speed"></div>
        </div>
      </div>
    </div>
  );
}

export default ScrollLine;
