import React, { useState, useEffect, useRef } from 'react';
import gsap from 'gsap';

const isMobile = () => {
  const ua = navigator.userAgent;
  return /Android|Mobi/i.test(ua);
};

const CursorHover = (props) => {
  if (typeof navigator !== 'undefined' && isMobile()) return null;

  const [position, setPosition] = useState({ x: -50, y: -50 });
  const [clicked, setClicked] = useState(false);
  const [linkHovered, setLinkHovered] = useState(false);
  const [cardHovered, setCardHovered] = useState(false);
  const [strokeLinkHovered, setStrokedLinkHovered] = useState(false);
  const [worksHovered, setWorksHovered] = useState(false);
  const [blogsHovered, setBlogsHovered] = useState(false);
  const circleRef = useRef(null);
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    addEventListeners();
    setTimeout(() => handleLinkHoverEvents(), 500);
    setTimeout(() => handleCardHoverEvents(), 500);
    setTimeout(() => handleStrokedLinkHoverEvents(), 500);
    setTimeout(() => handleWorksCardHover(), 500);
    handleLinkHoverEvents();

    return () => removeEventListeners();
  }, []);

  useEffect(() => {
    //Animation codex
    const rotateAnim = gsap.to(circleRef.current, {
      rotation: '+=360',
      ease: 'none',
      duration: 3.5,
      paused: false,
      repeat: '-1',
    });
    if (worksHovered) {
      //   rotateAnim.play();
    }
  }, [worksHovered]);

  const addEventListeners = () => {
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseenter', onMouseEnter);
    document.addEventListener('mouseleave', onMouseLeave);
    document.addEventListener('mousedown', onMouseDown);
    document.addEventListener('mouseup', onMouseUp);
  };

  const removeEventListeners = () => {
    document.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('mouseenter', onMouseEnter);
    document.removeEventListener('mouseleave', onMouseLeave);
    document.removeEventListener('mousedown', onMouseDown);
    document.removeEventListener('mouseup', onMouseUp);
  };

  const onMouseMove = (e) => {
    setPosition({ x: e.clientX, y: e.clientY });
  };

  const onMouseDown = () => {
    setClicked(true);
  };

  const onMouseUp = () => {
    setClicked(false);
  };

  const onMouseLeave = () => {
    setHidden(true);
  };

  const onMouseEnter = () => {
    setHidden(false);
  };

  const handleLinkHoverEvents = () => {
    document.querySelectorAll('.works-card-image-surface').forEach((el) => {
      el.addEventListener('mouseover', () => setLinkHovered(true));
      el.addEventListener('mouseout', () => setLinkHovered(false));
    });
  };

  const handleCardHoverEvents = () => {
    document.querySelectorAll('article.card').forEach((el) => {
      el.addEventListener('mouseover', () =>
        props.fromBlog ? setBlogsHovered(true) : setCardHovered(true)
      );
      el.addEventListener('mouseout', () =>
        props.fromBlog ? setBlogsHovered(false) : setCardHovered(false)
      );
    });
  };

  const handleStrokedLinkHoverEvents = () => {
    document.querySelectorAll('.stroked-text').forEach((el) => {
      el.addEventListener('mouseover', () => setStrokedLinkHovered(true));
      el.addEventListener('mouseout', () => setStrokedLinkHovered(false));
    });
  };

  const handleWorksCardHover = () => {
    document.querySelectorAll('.works-new-card').forEach((el) => {
      el.addEventListener('mouseover', () => setWorksHovered(true));
      el.addEventListener('mouseout', () => setWorksHovered(false));
    });
  };

  return (
    <div
      className={`cursor--hidden ${
        linkHovered || cardHovered || worksHovered || blogsHovered
          ? 'cursor-btn'
          : strokeLinkHovered
          ? 'stroke-hover'
          : ''
      }`}
      style={{
        left: `${position.x}px`,
        top: `${position.y}px`,
        transform: linkHovered || cardHovered ? 'scale(1.2) translate(-50%, -50%)' : '',
        border: worksHovered ? 'none' : '',
      }}
    >
      {linkHovered ? 'Explore' : cardHovered ? 'Read' : ''}
      {worksHovered ? (
        <div className="rotating-container" id="explorer-cursor" ref={circleRef}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 115.5 115.61" height="80">
            <g id="Layer_2" data-name="Layer 2">
              <g id="Layer_1-2" data-name="Layer 1">
                <path d="M65.28,2.12l-4-.3-.16,2,3,.23L64,5.75l-3-.23-.21,2.72,4.12.31-.13,1.68-6-.46L59.55,0l5.86.45Z" />
                <path d="M76.35,2.92l-4,3.68,2,5.91L72.2,12,70.83,7.76l-3.24,3-2.1-.54L70.08,6,68.35.85l2.11.54,1.12,3.49,2.67-2.51Z" />
                <path d="M80.24,10.91l-1.63-.75-1.68,3.62L75.22,13l4.13-8.89,3.33,1.55A4.5,4.5,0,0,1,84,6.48a2.58,2.58,0,0,1,.79,2.1,3,3,0,0,1-.29,1.1,3.18,3.18,0,0,1-.65.93,2.6,2.6,0,0,1-.9.59A3.4,3.4,0,0,1,80.24,10.91Zm-.92-2.27,1.55.72a1.57,1.57,0,0,0,1.24.14A1.24,1.24,0,0,0,82.81,8a1.58,1.58,0,0,0-.91-.86l-1.55-.72Z" />
                <path d="M82.49,16.57,88,8.45,89.54,9.5,85,16.23l3.42,2.31-.94,1.4Z" />
                <path d="M90.09,18.14a5.11,5.11,0,0,1,4.61-3.22,4.66,4.66,0,0,1,1.91.36,5.31,5.31,0,0,1,1.76,1.17,5.19,5.19,0,0,1,1.2,1.73,4.64,4.64,0,0,1,.4,1.9A4.87,4.87,0,0,1,99.6,22a5.34,5.34,0,0,1-2.75,2.81,5.13,5.13,0,0,1-1.86.42,4.89,4.89,0,0,1-1.92-.37,5.51,5.51,0,0,1-1.75-1.16,5.4,5.4,0,0,1-1.2-1.73,4.72,4.72,0,0,1,0-3.78ZM94,16.88a3.53,3.53,0,0,0-1.48.91,3.59,3.59,0,0,0-.88,1.5,3.19,3.19,0,0,0,0,1.68,3.06,3.06,0,0,0,.86,1.48,3,3,0,0,0,1.49.82,3.23,3.23,0,0,0,1.69-.06,3.4,3.4,0,0,0,1.47-.91A3.49,3.49,0,0,0,98,20.8a3.19,3.19,0,0,0,0-1.68,3.23,3.23,0,0,0-2.35-2.3A3.2,3.2,0,0,0,94,16.88Z" />
                <path d="M100.76,27.88l-.38-.57-3.61,2.42-1-1.57,8.14-5.46,2,3a3.07,3.07,0,0,1,.59,2.18,2.92,2.92,0,0,1-.4,1,2.72,2.72,0,0,1-1.82,1.22,2.77,2.77,0,0,1-1.11,0,2.54,2.54,0,0,1-1-.49l-1.69,5.55L99.1,33.2Zm1-1.51.88,1.31a1.26,1.26,0,0,0,.77.62,1,1,0,0,0,.77-.18,1.09,1.09,0,0,0,.48-.65,1.25,1.25,0,0,0-.28-1l-.87-1.31Z" />
                <path d="M110.39,37.87l-1.63-3.64-1.86.82,1.23,2.76-1.53.68-1.23-2.75-2.49,1.11,1.68,3.77-1.54.69-2.45-5.5,9-4,2.39,5.37Z" />
                <path d="M105.49,50.48a1.16,1.16,0,0,1,.81-.46,1.18,1.18,0,0,1,.91.23,1.14,1.14,0,0,1,.47.81,1.19,1.19,0,0,1-.23.91,1.23,1.23,0,0,1-.82.48,1.16,1.16,0,0,1-.9-.24,1.21,1.21,0,0,1-.47-.82A1.18,1.18,0,0,1,105.49,50.48Z" />
                <path d="M113.08,68.24l.51-4-2-.26-.39,3-1.67-.22.39-3-2.71-.35-.53,4.09L105,67.33l.77-6,9.72,1.26-.76,5.83Z" />
                <path d="M111.7,79.24,108.23,75l-6,1.67.66-2.07,4.27-1.14-2.83-3.4L105,68l4,4.8,5.26-1.45-.66,2.08-3.54.93,2.36,2.8Z" />
                <path d="M103.5,82.71l.84-1.58-3.52-1.87.88-1.67,8.66,4.59-1.72,3.25a4.58,4.58,0,0,1-.91,1.24,2.59,2.59,0,0,1-1.07.6,2.54,2.54,0,0,1-1.07.08,3.18,3.18,0,0,1-1.08-.35,3.08,3.08,0,0,1-.9-.7,2.76,2.76,0,0,1-.54-.93A3.38,3.38,0,0,1,103.5,82.71Zm2.32-.8-.8,1.51a1.63,1.63,0,0,0-.21,1.24,1.19,1.19,0,0,0,.58.67,1.21,1.21,0,0,0,.89.11,1.61,1.61,0,0,0,.91-.87l.8-1.51Z" />
                <path d="M97.74,84.64l7.83,5.91-1.14,1.5L98,87.16l-2.49,3.3-1.34-1Z" />
                <path d="M95.76,92.17a5.11,5.11,0,0,1,1.61,1.18,4.89,4.89,0,0,1,1,1.71,4.71,4.71,0,0,1-.15,3.77,5.59,5.59,0,0,1-3,2.8,4.85,4.85,0,0,1-1.93.31,5.17,5.17,0,0,1-1.85-.48,5.26,5.26,0,0,1-1.6-1.19,5.13,5.13,0,0,1-1-1.7,4.93,4.93,0,0,1-.32-1.88,4.88,4.88,0,0,1,.47-1.9,5.46,5.46,0,0,1,1.25-1.69A5.26,5.26,0,0,1,92,92a4.71,4.71,0,0,1,3.78.17Zm1,4A3.42,3.42,0,0,0,96,94.64a3.45,3.45,0,0,0-1.44-1,3.25,3.25,0,0,0-1.69-.12,3.25,3.25,0,0,0-2.42,2.22,3.23,3.23,0,0,0,0,1.69A3.6,3.6,0,0,0,92.68,100a3.1,3.1,0,0,0,1.68.11,3.25,3.25,0,0,0,2.42-2.22A3.19,3.19,0,0,0,96.81,96.16Z" />
                <path d="M85.48,102.3l.6-.36-2.23-3.72,1.62-1,5,8.42-3.06,1.83a3.13,3.13,0,0,1-2.22.47,3.07,3.07,0,0,1-1-.45,2.75,2.75,0,0,1-.76-.83,2.69,2.69,0,0,1-.37-1.06,2.92,2.92,0,0,1,.09-1.1,2.44,2.44,0,0,1,.54-1l-5.45-2,2-1.2Zm1.46,1.09-1.35.8a1.25,1.25,0,0,0-.66.74,1,1,0,0,0,.13.78,1.12,1.12,0,0,0,.63.51,1.28,1.28,0,0,0,1-.22l1.35-.81Z" />
                <path d="M75,111.38,78.71,110,78,108.06l-2.82,1.08-.6-1.57,2.81-1.08-1-2.55-3.86,1.48-.6-1.57,5.61-2.16,3.52,9.16L75.59,113Z" />
                <path d="M66.27,105.33a1.14,1.14,0,0,1,.48.79,1.15,1.15,0,0,1-.21.92,1.13,1.13,0,0,1-.79.49,1.19,1.19,0,0,1-.92-.21,1.21,1.21,0,0,1-.5-.81,1.21,1.21,0,0,1,1-1.39A1.13,1.13,0,0,1,66.27,105.33Z" />
                <path d="M48.7,113.31l4,.42.22-2-3-.32.18-1.68,3,.32.29-2.71-4.1-.44.17-1.67,6,.64-1,9.75L48.52,115Z" />
                <path d="M37.66,112.18l4.14-3.56-1.8-6,2.08.61,1.24,4.25,3.33-2.92,2.08.61L44,109.27l1.57,5.22-2.09-.6-1-3.52-2.75,2.42Z" />
                <path d="M34,104.09l1.6.8,1.79-3.57,1.69.85-4.39,8.76-3.29-1.65a4.32,4.32,0,0,1-1.26-.87,2.56,2.56,0,0,1-.63-1.06,2.7,2.7,0,0,1-.1-1.07,3.25,3.25,0,0,1,.32-1.09,3.19,3.19,0,0,1,.68-.91,2.68,2.68,0,0,1,.92-.56A3.36,3.36,0,0,1,34,104.09Zm.85,2.3-1.53-.77a1.6,1.6,0,0,0-1.24-.18,1.23,1.23,0,0,0-.74,1.49,1.58,1.58,0,0,0,.88.89l1.53.76Z" />
                <path d="M31.93,98.35l-5.73,8-1.53-1.1,4.75-6.59L26.07,96.2l1-1.37Z" />
                <path d="M24.39,96.56a5.09,5.09,0,0,1-1.15,1.63,4.91,4.91,0,0,1-1.68,1.09,4.71,4.71,0,0,1-1.87.36,4.63,4.63,0,0,1-1.9-.42A5.36,5.36,0,0,1,16.07,98a5.2,5.2,0,0,1-1.15-1.76,4.72,4.72,0,0,1-.35-1.92A4.94,4.94,0,0,1,15,92.46a5.13,5.13,0,0,1,1.16-1.63,5.18,5.18,0,0,1,1.67-1.09,4.76,4.76,0,0,1,1.88-.36,4.7,4.7,0,0,1,1.9.42A5.32,5.32,0,0,1,23.33,91a5.52,5.52,0,0,1,1.15,1.76,4.89,4.89,0,0,1,.35,1.92A5.16,5.16,0,0,1,24.39,96.56Zm-4,1.14a3.57,3.57,0,0,0,1.5-.87,3.36,3.36,0,0,0,.92-1.47,3.1,3.1,0,0,0,.08-1.68,3.19,3.19,0,0,0-2.28-2.37,3.19,3.19,0,0,0-1.68,0,3.6,3.6,0,0,0-1.51.87,3.46,3.46,0,0,0-.92,1.47,3.13,3.13,0,0,0,.74,3.18,3.09,3.09,0,0,0,1.46.87A3.23,3.23,0,0,0,20.43,97.7Z" />
                <path d="M14,86.51l.37.59,3.67-2.32,1,1.6-8.3,5.22-1.9-3a3,3,0,0,1-.52-2.2,2.71,2.71,0,0,1,.43-1,2.48,2.48,0,0,1,.8-.78,2.74,2.74,0,0,1,2.16-.33,2.5,2.5,0,0,1,1,.52l1.84-5.5,1.25,2ZM13,88l-.83-1.33a1.26,1.26,0,0,0-.76-.65,1,1,0,0,0-.77.16,1.09,1.09,0,0,0-.5.64,1.3,1.3,0,0,0,.25,1l.84,1.33Z" />
                <path d="M4.69,76.22l1.52,3.69,1.88-.77L6.94,76.35l1.56-.63,1.14,2.79,2.53-1L10.6,73.65,12.15,73l2.29,5.57L5.37,82.3,3.14,76.86Z" />
                <path d="M10,63.77A1.25,1.25,0,0,1,8.24,64a1.17,1.17,0,0,1-.45-.82,1.21,1.21,0,0,1,.27-.91,1.19,1.19,0,0,1,.82-.45,1.13,1.13,0,0,1,.9.27A1.24,1.24,0,0,1,10,63.77Z" />
                <path d="M2.16,50l-.33,4,2,.17.25-3,1.67.14-.25,3,2.72.22.34-4.11,1.68.14-.5,6L0,55.71l.49-5.86Z" />
                <path d="M3,38.94,6.69,43l5.92-2-.55,2.1L7.84,44.49l3,3.26-.56,2.09L6.1,45.23.91,46.92l.56-2.1L5,43.72,2.48,41Z" />
                <path d="M11.05,35.11l-.76,1.62,3.61,1.71-.81,1.7L4.23,36,5.8,32.63a4.37,4.37,0,0,1,.85-1.28,2.62,2.62,0,0,1,2.1-.78,3.06,3.06,0,0,1,1.1.3,2.88,2.88,0,0,1,.92.66,2.6,2.6,0,0,1,.59.9A3.42,3.42,0,0,1,11.05,35.11ZM8.77,36l.73-1.54a1.6,1.6,0,0,0,.15-1.25A1.16,1.16,0,0,0,9,32.58a1.2,1.2,0,0,0-.89-.07,1.62,1.62,0,0,0-.87.91L6.55,35Z" />
                <path d="M16.73,32.9,8.64,27.35,9.71,25.8l6.7,4.59L18.74,27l1.39,1Z" />
                <path d="M18.36,25.28a5,5,0,0,1-1.65-1.11,5.06,5.06,0,0,1-1.13-1.65,5,5,0,0,1-.4-1.87,4.86,4.86,0,0,1,.38-1.91A5.57,5.57,0,0,1,16.74,17a5.27,5.27,0,0,1,1.74-1.18,4.64,4.64,0,0,1,3.77,0,4.94,4.94,0,0,1,1.66,1.11A5.22,5.22,0,0,1,25,18.57a5,5,0,0,1,.4,1.87,4.86,4.86,0,0,1-.38,1.91,5.41,5.41,0,0,1-1.18,1.75,5.33,5.33,0,0,1-1.73,1.18,4.89,4.89,0,0,1-1.91.4A5,5,0,0,1,18.36,25.28Zm-1.22-3.94a3.54,3.54,0,0,0,.9,1.49,3.42,3.42,0,0,0,1.49.89,3.19,3.19,0,0,0,3.17-.8,3.2,3.2,0,0,0,.78-3.18,3.57,3.57,0,0,0-.9-1.48,3.42,3.42,0,0,0-1.49-.89,3.15,3.15,0,0,0-3.16.81,3.15,3.15,0,0,0-.84,1.48A3.19,3.19,0,0,0,17.14,21.34Z" />
                <path d="M28.16,14.7l-.58.38L30,18.71l-1.58,1L23,11.57l3-2A3.09,3.09,0,0,1,28.16,9a2.87,2.87,0,0,1,1,.4,2.71,2.71,0,0,1,1.21,1.83,2.6,2.6,0,0,1,0,1.11,2.44,2.44,0,0,1-.5,1l5.54,1.72L33.46,16.4Zm-1.5-1L28,12.81a1.28,1.28,0,0,0,.63-.76,1.07,1.07,0,0,0-.18-.77,1,1,0,0,0-.65-.48,1.24,1.24,0,0,0-1,.26l-1.31.87Z" />
                <path d="M38.24,5.14l-3.66,1.6L35.4,8.6l2.76-1.21.67,1.54-2.76,1.21,1.09,2.5L41,11l.67,1.54-5.51,2.41L32.18,6,37.57,3.6Z" />
                <path d="M50.81,10.13a1.25,1.25,0,0,1-.23-1.72,1.16,1.16,0,0,1,.81-.47A1.22,1.22,0,0,1,52.78,9a1.24,1.24,0,0,1-2,1.13Z" />
              </g>
            </g>
          </svg>
        </div>
      ) : null}

      {blogsHovered ? (
         <svg xmlns="http://www.w3.org/2000/svg" height="36" viewBox="0 0 512 512">
         <g fill="#fafafa">
           <path d="M34 256l26.2 26.2c108 108 283.7 108 391.7 0L478 256l-26.2-26.2c-108-108-283.7-108-391.7 0L34 256zm222 126.2c-75.8 0-151.6-28.9-209.3-86.6l-32.9-32.9c-3.7-3.7-3.7-9.7 0-13.5l32.9-32.9c115.4-115.4 303.2-115.4 418.6 0l32.9 32.9c3.7 3.7 3.7 9.7 0 13.5l-32.9 32.9c-57.7 57.7-133.5 86.6-209.3 86.6z"></path>
           <path d="M256 183.5c-40 0-72.5 32.5-72.5 72.5s32.5 72.5 72.5 72.5 72.5-32.5 72.5-72.5-32.5-72.5-72.5-72.5zm0 164c-50.5 0-91.5-41.1-91.5-91.5 0-50.5 41.1-91.5 91.5-91.5s91.5 41.1 91.5 91.5c0 50.5-41 91.5-91.5 91.5z"></path>
         </g>
       </svg>
      ) : null}
    </div>
  );
};

export default CursorHover;
