import React, { useState, useEffect } from 'react';

import { PortfolioProvider } from '../../context/context';

import {
  heroData,
  aboutData,
  projectsData,
  contactData,
  footerData,
  blogsData,
} from '../../mock/data';
import Footer from '../Footer/Footer';
import { Scrollbars } from 'react-custom-scrollbars';
import BlogHero from '../BlogHero/BlogHero';
import BlogsGrid from '../BlogsGrid';

import Splitting from 'splitting';
import BlogQuote from '../BlogQuoteSection/BlogQuoteSection';
import CursorComponent from '../CursorComponent';

function Blog(props) {
  const [hero, setHero] = useState({});
  const [about, setAbout] = useState({});
  const [projects, setProjects] = useState([]);
  const [contact, setContact] = useState({});
  const [footer, setFooter] = useState({});
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    setHero({ ...heroData });
    setAbout({ ...aboutData });
    setProjects([...projectsData]);
    setBlogs([...blogsData]);
    setContact({ ...contactData });
    setFooter({ ...footerData });
  }, []);

  useEffect(() => {
    // Animation for fading out
    Splitting({
      /* target: String selector, Element, Array of Elements, or NodeList */
      target: '[data-splitting]',
      /* by: String of the plugin name */
      by: 'chars',
      /* key: Optional String to prefix the CSS variables */
      key: null,
    });
  }, []);

  return (
    <Scrollbars style={{ height: '100vh', width: '100%' }}>
      <PortfolioProvider value={{ hero, about, projects, contact, blogs, footer }}>
        <BlogHero />
        <BlogsGrid />
        <BlogQuote />
        <Footer />
        <CursorComponent fromBlog={true}/>
      </PortfolioProvider>
    </Scrollbars>
  );
}

export default Blog;
