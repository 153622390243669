import React, { useContext, useState, useEffect, useRef } from 'react';
import { Container } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import { useIntersection } from 'react-use';
import PortfolioContext from '../../context/context';

import ScrollLine from '../ScrollLine';
import BlogAnim from '../BlogAnim';


const randomQuotesArray = [
  ['Nothing is true', 'Everything is permitted', 'Altair Ibn-LaʼAhad (AC)'],
  ['What is bravery', 'Without a dash of recklessness', 'Dark Souls'],
  ['A man chooses', 'a slaves obeys', 'A. Ryan (BioShock)'],
  ['Inspiration does exist', 'but it must find you working', 'Pablo Picasso'],
  ['After all', 'Life goes on', 'Alcatraz'],
  ['Fall down 99 times', 'but rise up 100 times', 'Japanese Proverb'],
  ['Sometimes later becomes never', 'Do it now', 'Latin Proverb'],
  ['One day or day one', 'You decide', 'Greek Proverb']
]


const BlogQuote = (props) => {
  const quoteRef = useRef(null);
  const [quote, setQuote] = useState([])
  const intersection = useIntersection(quoteRef, {
    root: null,
    rootMargin: '0px',
    threshold: 0.4,
  });

  const { hero } = useContext(PortfolioContext);
  const { title, name, subtitle, cta } = hero;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
    const availableQuotes = randomQuotesArray;
    let randomInstance = Math.floor(Math.random() * availableQuotes.length);
    setQuote(availableQuotes[randomInstance])

  }, []);

  return (
    <section id="blog-quote" className="jumbotron" ref={quoteRef}>
      <Container className="px-0 mt-5 blog-quote-container">
        <div className='skewed-narrow'>
        <Fade top duration={800} delay={1000} distance="60px" when={intersection && intersection.intersectionRatio > 0.1}>
          <div className='quote-container'>
            <h1 className="blog-quote-title quote-up ">
              <strong>{quote[0]}.</strong>
            </h1>
            <h1 className="blog-quote-title quote-down ">
              <strong>{quote[0]}.</strong>
            </h1>

          </div>

        </Fade>
        <Fade top duration={800} delay={1500} distance="40px" when={intersection && intersection.intersectionRatio > 0.1}>
        <div className='quote-container'>
          <h1 className="blog-quote-title second-line">
            {quote[1]}.
          </h1>
          </div>
        </Fade>
        <Fade top duration={1000} delay={2100} distance="5px" when={intersection && intersection.intersectionRatio > 0.1}>
          <p className="mt-3 pt-3">
            - {quote[2]}
          </p>
        </Fade>
        </div>
      
      </Container>
    </section>
  );
};

export default BlogQuote;
