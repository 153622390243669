import TransitionLink from 'gatsby-plugin-transition-link';
import React from 'react';

const BlogsCard = (props) => {
  return (
    <div>
      <article className="card position-relative" style={{pointerEvents: props.readTime ? 'auto' : 'none'}}>
        <TransitionLink to={props.to} exit={props.exit} entry={props.entry} className="card__link">
          {props.isBlog && props.isNew && (
            <div className="blog-new-tag">
              <span> </span>
            </div>
          )}
          <div className="card__header">
            <div className="card-title-container">
              <h3 className="card__header-title up-title">{props.title}</h3>
              <h3 className="card__header-title down-title">{props.title}</h3>
            </div>
            <p className="card__header-meta">{props.subtitle}</p>
            {props.isBlog && props.bDate && (
              <>
                <div className="blog-tags">
                  <span>{props.bDate}</span>{' '}
                  {props.readTime && (
                    <span>
                      <span className="line-span">|</span> <span>{props.readTime} min read</span>
                    </span>
                  )}{' '}
                </div>
              </>
            )}{' '}
            <div className="card__header-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8.322"
                height="14.721"
                viewBox="0 0 8.322 14.721"
              >
                <path
                  id="chevron-small-right"
                  d="M17.6,16l-5.026-5.472a1.112,1.112,0,0,1,0-1.565,1.089,1.089,0,0,1,1.55,0l6.128,6.253a1.114,1.114,0,0,1,0,1.566l-6.128,6.253a1.086,1.086,0,0,1-1.55,0,1.112,1.112,0,0,1,0-1.565Z"
                  transform="translate(-12.252 -8.639)"
                  fill="#fefefe"
                />
              </svg>
            </div>
          </div>
        </TransitionLink>
      </article>
    </div>
  );
};

export default BlogsCard;
