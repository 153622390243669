import React, { useContext, useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import Typewriter from 'typewriter-effect';

import PortfolioContext from '../../context/context';

import ScrollLine from '../ScrollLine';
import BlogAnim from '../BlogAnim';

const BlogHeroConvos = [
  [
    'It is not what you look at that matters, ',
    'it is what you <span style="color: #fff"><b>SEE</b></span>',
    'Perspective is often an impressive slave',
    'but a bitter master',
  ],
  [
    'A person who thinks all the time, ',
    'Has nothing to think about except <span style="color: #fff"><b>THOUGHTS</b></span>',
    'So he loses touch with <span style="color: #fff"><i>reality</i></span>',
    'And lives in a world of illusions',
    'By thoughts, I mean specifically,',
    'chatter in the skull',
    'Perpetual and compulsive repetition of words',
    'Of reckoning and calculating',
    'I am not saying that thinking is bad',
    'Like everything else, it is useful in moderation',
  ],
  [
    'When our universe is in harmony with us,',
    'The eternal, we know it as <span style="color: #fff"><b>truth</b></span>',
    'We feel it as <span style="color: #fff"><b>beauty</b></span>',
    'Equipped with our <span style="color: #fff"><i>five</i></span> senses,',
    'We explore the universe around us',
    'And we call this adventure <span style="color: #fff"><b>SCIENCE</b></span>',
  ],
  [
    'A person who thinks all the time, ',
    'Has nothing to think about except <span style="color: #fff"><b>THOUGHTS</b></span>',
    'So he loses touch with <span style="color: #fff"><i>reality</i></span>',
    'And lives in a world of illusions',
    'By thoughts, I mean specifically,',
    'chatter in the skull',
    'Perpetual and compulsive repetition of words',
    'Of reckoning and calculating',
    'I am not saying that thinking is bad',
    'Like everything else, it is useful in moderation',
  ],
];

const BlogHero = (props) => {
  const { hero } = useContext(PortfolioContext);
  const { title, name, subtitle, cta } = hero;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  const availableConvos = BlogHeroConvos;
  let randomInstance = Math.floor(Math.random() * availableConvos.length);
  const currentConvo = availableConvos[randomInstance];

  return (
    <section id="blog" className="jumbotron">
      <Container className="px-0 mt-5">
        <Fade top duration={1000} delay={1000} distance="30px">
          <div className="pb-md-5 pb-2 blog-anim-container">
            <BlogAnim />
          </div>
        </Fade>
        <h1 className="blog-title mt-4" data-splitting>
          Welcome to my blog
        </h1>

        <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
          <Typewriter
            options={{
              strings: currentConvo,
              loop: true,
              delay: 40,
              deleteSpeed: 30,
              autoStart: true,
              cursor: ' _',
              wrapperClassName: 'blog-sub-title',
            }}
          />
          {/* <Typewriter
            onInit={(typewriter) => {
              typewriter
              .pauseFor(2000)
              .typeString('It is not what you look at that matters,')
              .pauseFor(1500)
              .deleteAll()
              .typeString('it is what you <span style="color: #e74c3c"><b>SEE</b></span>')
              .pauseFor(1500)
              .deleteAll()
              .start();
            }}
            options={{
              loop: true,
              delay: 30,
              deleteSpeed: 20,
              cursor: ' _',
              wrapperClassName: 'blog-sub-title'
            }}
          /> */}
        </Fade>

        <div className="pt-5">
          <ScrollLine />
        </div>
      </Container>
    </section>
  );
};

export default BlogHero;
