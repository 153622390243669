import React, { useContext, useEffect, useState, useRef } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PortfolioContext from '../../context/context';
import gsap from 'gsap';
import { useIntersection } from 'react-use';
import { TransitionPortal } from 'gatsby-plugin-transition-link';
import BlogsCard from '../BlogsCard';

const BlogsGrid = () => {
  const { blogs } = useContext(PortfolioContext);
  const sectionRef = useRef(null);
  const cardsRef = useRef([]);

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const layoutContents = useRef();
  const transitionCover = useRef();
  // All the ref to be observed
  const intersection = useIntersection(sectionRef, {
    root: null,
    rootMargin: '0px',
    threshold: 0.4,
  });

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  // Animation for fading in
  const fadeIn = (element) => {
    gsap.to(element, 1, {
      opacity: 1,
      delay: 0.8,
      y: 20,
      ease: 'power4.out',
      stagger: {
        amount: 1,
      },
    });
  };
  // Animation for fading out
  const fadeOut = (element) => {
    gsap.to(element, 1, {
      opacity: 0,
      y: -20,
      ease: 'power4.out',
      stagger: {
        amount: 1,
      },
    });
  };

  const exitHorizontal = ({ length }, direction) => {
    const seconds = length;

    const directionTo = direction === 'left' ? '-100%' : '100%';
    const directionFrom = direction === 'left' ? '100%' : '-100%';

    return gsap
      .timeline()
      .set(transitionCover.current, { x: directionFrom, display: 'block' })
      .to(transitionCover.current, {
        x: '0%',
        ease: 'power1.easeInOut',
        duration: seconds / 2,
      })
      .set(layoutContents.current, { opacity: 0 })
      .to(transitionCover.current, {
        x: directionTo,
        ease: 'power1.easeInOut',
        duration: seconds / 2,
      });
  };

  useEffect(() => {
    intersection && intersection.intersectionRatio < 0.1
      ? fadeOut('.card-containery')
      : fadeIn('.card-containery');
  }, [intersection]);
  // checking to see when the vieport is visible to the user

  return (
    <section id="blogs" ref={sectionRef}>
      <Container className="px-0 ">
        <div className="blogs-wrapper">
          <div className="blogs-grid mt-0 pb-sm-5">
            {blogs.map((blog, index) => {
              const {
                title,
                info,
                info2,
                url,
                repo,
                img,
                id,
                to,
                subtitle,
                backgroundImage,
                statusNew,
                blogDate,
                readTime,
              } = blog;
              return (
                <div className="card-containery" key={id} ref={cardsRef}>
                  <BlogsCard
                    title={title}
                    subtitle={subtitle}
                    to={`/blog/${to}`}
                    bImg={backgroundImage}
                    isBlog={true}
                    isNew={statusNew}
                    bDate={blogDate}
                    readTime={readTime} 
                    exit={{
                      length: 1.0,
                      trigger: ({ exit }) => exitHorizontal(exit, 'left'),
                    }}
                    entry={{
                      delay: 0.5,
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </Container>
      <TransitionPortal>
        <div
          ref={transitionCover}
          style={{
            position: 'fixed',
            background: '#131313',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            display: 'none',
          }}
        />
      </TransitionPortal>
    </section>
  );
};

export default BlogsGrid;
